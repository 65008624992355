import { Flex, Heading, Skeleton, Text } from '@pancakeswap/uikit'
import Balance from 'components/Balance'
import cakeAbi from 'config/abi/cake.json'
import tokens from 'config/constants/tokens'
import { useTranslation } from 'contexts/Localization'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useEffect, useState, useMemo } from 'react'
import { usePriceCakeBusd, usePollCoreFarmData, useFarms } from 'state/farms/hooks'
import { useFetchPublicPoolsData, usePools } from 'state/pools/hooks'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'
import styled from 'styled-components'
import { formatBigNumber, formatLocalisedCompactNumber } from 'utils/formatBalance'
import { multicallv2 } from 'utils/multicall'
import useSWR from 'swr'
import { SLOW_INTERVAL } from 'config/constants'

const StyledColumn = styled(Flex)<{ noMobileBorder?: boolean }>`
  flex-direction: column;
  ${({ noMobileBorder, theme }) =>
    noMobileBorder
      ? `${theme.mediaQueries.md} {
           padding: 0 16px;
           border-left: 1px ${theme.colors.inputSecondary} solid;
         }
       `
      : `border-left: 1px ${theme.colors.inputSecondary} solid;
         padding: 0 8px;
         ${theme.mediaQueries.sm} {
           padding: 0 16px;
         }
       `}
`

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  margin-top: 24px;
  grid-template-columns: repeat(2, auto);

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-gap: 16px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 32px;
    grid-template-columns: repeat(4, auto);
  }
`

const emissionsPerBlock = 7

const CakeDataRow = () => {
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [loadData, setLoadData] = useState(false)
  const {
    data: { cakeSupply, burnedBalance} = {
      cakeSupply: 0,
      burnedBalance: 0,
    },
  } = useSWR(
    loadData ? ['cakeDataRow'] : null,
    async () => {
      const totalSupplyCall = { address: tokens.cake.address, name: 'totalSupply' }
      const burnedTokenCall = {
        address: tokens.cake.address,
        name: 'balanceOf',
        params: ['0x000000000000000000000000000000000000dEaD'],
      }
      const tokenDataResultRaw = await multicallv2(cakeAbi, [totalSupplyCall, burnedTokenCall], {
        requireSuccess: false,
      })
      const [totalSupply, burned] = tokenDataResultRaw.flat()

      return {
        cakeSupply: totalSupply && burned ? +formatBigNumber(totalSupply.sub(burned)) : 0,
        burnedBalance: burned ? +formatBigNumber(burned) : 0,
      }
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )
  const cakePriceBusd = usePriceCakeBusd()
  const mcap = cakePriceBusd.times(cakeSupply)
  const mcapString = formatLocalisedCompactNumber(mcap.toNumber())

  usePollCoreFarmData()
  useFetchPublicPoolsData()

  const { data: farmData } = useFarms()
  const { pools: poolData } = usePools()

  const farmTVL = useMemo(() => {
    return farmData.reduce((prev, curr) => {
      return (
        prev +
        (curr?.quoteTokenAmountMc && curr.lpSymbol !== 'SOS'
          ? new BigNumber(curr?.quoteTokenAmountMc).times(curr?.quoteTokenPriceBusd).times(2).toNumber()
          : 0)
      )
    }, 0)
  }, [farmData])

  const poolTVL = useMemo(() => {
    return new BigNumber(poolData?.[0]?.totalStaked).times(cakePriceBusd).div(BIG_TEN.pow(18)).toNumber()
  }, [poolData, cakePriceBusd])

  const tvl = farmTVL + poolTVL
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '0'

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true)
    }
  }, [isIntersecting])

  return (
    <div>
      <Grid>
        <Flex flexDirection="column">
          <Text color="textSubtle">{t('Total supply')}</Text>
          {cakeSupply ? (
            <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={cakeSupply} />
          ) : (
            <>
              <div ref={observerRef} />
              <Skeleton height={24} width={126} my="4px" />
            </>
          )}
        </Flex>
        <StyledColumn>
          <Text color="textSubtle">{t('Burned to date')}</Text>
          {burnedBalance ? (
            <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={burnedBalance} />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn noMobileBorder>
          <Text color="textSubtle">{t('Market cap')}</Text>
          {mcap?.gt(0) && mcapString ? (
            <Heading scale="lg">{t('$%marketCap%', { marketCap: mcapString })}</Heading>
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn>
          <Text color="textSubtle">{t('Current emissions')}</Text>

          <Heading scale="lg">{t('%cakeEmissions%/block', { cakeEmissions: emissionsPerBlock })}</Heading>
        </StyledColumn>
      </Grid>
      <br />
      <div style={{ textAlign: 'center' }}>
        <Text color="textSubtle">{t('Total Value Locked (TVL)')}</Text>
        {tvlString ? (
          <Heading scale="lg">{t('$%tvl%', { tvl: tvlString })}</Heading>
        ) : (
          <Skeleton height={24} width={126} my="4px" />
        )}
      </div>
    </div>
  )
}

export default CakeDataRow
