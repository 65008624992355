import styled from 'styled-components'
import { Flex, Heading, Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@web3-react/core'
import SunburstSvg from './SunburstSvg'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Welcome = () => {
  const { t } = useTranslation()

  return (
    <>
      <BgWrapper>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <StyledSunburst />
        </Flex>
      </BgWrapper>
      <Wrapper>
        <Heading textAlign="center" scale="xl">
          {t('Welcome to DongasFinance')}
        </Heading>
        <Text textAlign="center" size="lg">
          {t('The Dongas Finance Next Generation DeFi On BSC')}
        </Text>
        <Link external href="https://docs.dongas.finance/">
          {t('Learn More')}
        </Link>
      </Wrapper>
    </>
  )
}

export default Welcome
